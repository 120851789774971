<template>
    <div class="container mt-4 mt-md-5">
        <router-view />
    </div>
</template>

<script>
    export default {
        
    }
</script>
